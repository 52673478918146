@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Mitr&display=swap);
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
  }


